import styled from "styled-components";

import {
    Container as BaseContainer,
    Subtitle as BaseSubtitle,
    Button
} from "components/ui";

import { media } from "constants/media";

export const Background = styled.div`
    background-color: var(--color-purple-55);
    color: var(--color-white);

    h1 {
        word-break: auto-phrase;
    }

    h1,
    p {
        color: var(--color-white);
    }
`;

export const Container = styled(BaseContainer)`
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;

    ${media.mdUp} {
        flex-direction: column-reverse;
        gap: 48px;
        min-height: 348px;
        padding-right: 0;
    }

    ${media.lgUp} {
        flex-direction: row;
        overflow: visible;
    }
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 16px;
    hyphens: auto;
    overflow-wrap: break-word;
    padding: 24px 0;

    p {
        font-family: var(--font-secondary);
        font-size: var(--type-paragraph-large-font-size);
        line-height: var(--type-paragraph-large-line-height);
    }

    ${media.mdUp} {
        gap: 24px;
        margin: auto 0;
    }

    ${media.lgUp} {
        flex: 1 0 576px;
        padding: 80px 0;
    }
`;

export const Title = styled.h1`
    font-size: 32px;

    ${media.mdUp} {
        font-size: 40px;
        line-height: 52px;
    }
`;

export const Subtitle = styled(BaseSubtitle)`
    font-weight: var(--font-weight-extra-bold);
    text-transform: uppercase;
`;

export const MoreInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${media.lgUp} {
        gap: 40px;
    }
`;

export const InputWrapper = styled.div`
    max-width: 392px;

    label > span {
        color: var(--color-white);
    }
`;

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledButton = styled(Button)`
    width: fit-content;
`;
