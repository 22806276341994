import styled, { css } from "styled-components";

import { media, sizes } from "constants/media";

export const Wrapper = styled.div<{
    isOpen: boolean;
}>`
    left: 0;
    max-height: calc(100vh - 80px);
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transform: translateX(100vw);
    transition: all var(--default-transition-duration) ease;
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    width: 100vw;
    z-index: var(--z-index-submenu);

    ${media.mdUp} {
        background-color: var(--color-white);
        bottom: auto;
        left: auto;
        margin-top: -1px;
        max-height: ${({ isOpen }) => (isOpen ? "100vh" : 0)};
        overflow-y: initial;
        position: relative;
        transform: translate(0, 0);
    }

    ${media.lgUp} {
        left: 0;
        margin-top: 0;
        max-height: fit-content;
        overflow: visible;
        padding: 26px 0;
        position: absolute;
        top: 100%;
        transform: translateY(${({ isOpen }) => (isOpen ? "0" : "-10px")});
        z-index: var(--z-index-header);
    }

    ${media.xlUp} {
        padding: 56px 0;
        position: fixed;
        top: 120px;
    }
`;

export const StyledContainer = styled.div<{
    columns: number;
}>`
    display: grid;
    grid-gap: 32px;
    padding: 24px 24px 100px;

    ${media.mdUp} {
        grid-gap: 40px;
        padding: 32px;
    }

    ${media.lgUp} {
        grid-gap: 0 24px;
        grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
        margin: 0 auto;
        max-width: ${sizes.lg}px;
        padding: 0;
    }

    ${media.xlUp} {
        grid-gap: 0 48px;
        max-width: ${sizes.xl}px;
    }
`;

export const Title = styled.strong`
    color: var(--color-grey-55);
    display: block;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase;
`;

const List = styled.ul`
    display: inline-grid;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    ${media.lgUp} {
        width: auto;
    }
`;

export const PrimaryMenu = styled(List)<{
    columns: number;
}>`
    grid-gap: 16px;

    ${media.mdUp} {
        grid-gap: 24px;
        grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    }

    ${media.lgUp} {
        grid-gap: 8px;
    }

    ${media.xlUp} {
        grid-gap: 16px;
    }
`;

export const PrimaryMenuItem = styled.li<{ columns: number }>`
    ${media.mdUp} {
        grid-column: span ${({ columns }) => columns};
    }
`;

function determineStoryStyling(type: "default" | "story") {
    switch (type) {
        case "story":
            return css`
                grid-gap: 8px;

                ${media.mdUp} {
                    grid-gap: 8px;
                    grid-template-columns: repeat(2, 1fr);

                    li:last-child {
                        grid-column: span 2;
                    }
                }

                ${media.lgUp} {
                    grid-gap: 24px 48px;
                    grid-template-columns: repeat(3, 1fr);
                }
            `;
        case "default":
        default:
            return css`
                ${media.mdUp} {
                    grid-gap: 0 48px;
                    grid-template-columns: repeat(3, 1fr);
                }
            `;
    }
}

export const SecondaryMenu = styled(List)<{
    type: "default" | "story";
}>`
    ${({ type }) => determineStoryStyling(type)}

    ${media.lgUp} {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const Column = styled.div`
    :last-of-type {
        ${Title} {
            margin-bottom: 16px;

            ${media.lgUp} {
                margin-bottom: 24px;
            }
        }
    }
`;
