import styled from "styled-components";

import { Container } from "components/ui";
import { Heading } from "components/ui";
import { StoryblokButton } from "components/shared";

import { media } from "constants/media";

export const ContentWrapper = styled.div`
    ${media.mdUp} {
        margin: 0;
        width: 50%;
    }
`;

export const StyledContainer = styled(Container)<{ isEven?: boolean }>`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 0;

    ${media.mdUp} {
        align-items: unset;
        flex-direction: ${props => (props.isEven ? "row-reverse" : "row")};
        margin: 112px auto;
    }
`;

export const AvatarsWrapper = styled.div`
    position: relative;
    width: fit-content;

    ${media.mdUp} {
        margin: 0 75px 0 -75px;
        width: 50%;
    }

    ${media.lgUp} {
        margin: -80px 100px 0 -100px;
    }
`;

export const StyledHeading = styled(Heading)`
    hyphens: auto;
    margin-bottom: 16px;
    word-break: auto-phrase;

    ${media.mdUp} {
        margin-bottom: 24px;
    }
`;

export const StyledDescription = styled.div`
    margin-bottom: 24px;

    ${media.mdUp} {
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 40px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const StyledStoryblokButton = styled(StoryblokButton)`
    width: fit-content;
`;
